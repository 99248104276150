.login {
    background: white;
    /* height: 370px; */
    width: 280px;
  }
  fieldset {
    border: none;
  }
  .logo {
    /* transform: translate(32%, 10px); */
    text-align: center;
  }
  input {
    background: whitesmoke;
    border: none;
    height: 25px;
    width: 250px;
  }
  input:focus {
    outline: none;
  }
  label {
    font-size: 10px;
    font-weight: 600;
    color: grey;
  }
  sup {
    color: red;
  }
  .login-submit {
    width: 100%;
    margin-left: 0px;
    margin-top: 24px;
    background: #005691;
    color: white;
    height: 25px;
  }
  .form-error {
    color: red;
    background: white;
    font-size: 10px;
  }
  .button {
    margin-left: 64px;
    margin-top: 20px;
    color:#005691;
    background: white;
    border: none;
  }
  button:focus {
    outline: none;
  }
  