* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background: #efefef;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
}

.loading-indicator:before {
  content: "";
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: url("./assets/images/loader/bosch_gif.gif");
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 10000;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

a {
  text-decoration: none;
}

.login-box {
  /* background: white; */
  height: 100%;
  width: 100%;
  background: url(./assets/images/png/login_bg.jpg) center no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  margin: 0 0 0 10px;
}
.background {
  height: 100%;
  width: 100%;
}
.login-forget {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pointer {
  cursor: context-menu;
}
